@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-sky-950;
  }
}

@layer components {
  .form-input:focus + .form-label,
  .form-input:not(:placeholder-shown) + .form-label {
    @apply text-xs text-sky-500;
  }
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

body {
  --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.btn-floating-whatsapp {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
  background-color: #25d366;
  border-radius: 50%;
  user-select: none;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 15%);
  z-index: 9998;
}
.btn-floating-whatsapp svg {
  width: 70%;
  height: 70%;
}

.btn-floating-whatsapp__animated:before {
  content: "";
  border-color: inherit;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 0 0 rgba(37, 211, 101, 0);
  animation: _pulse 1.2s infinite;
}

/* keyframes */
@keyframes _pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 101, 0.75);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(37, 211, 101, 0);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;  
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;
          
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}